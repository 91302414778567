// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-tsx": () => import("./../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-styleguide-button-tsx": () => import("./../src/pages/styleguide/button.tsx" /* webpackChunkName: "component---src-pages-styleguide-button-tsx" */),
  "component---src-pages-styleguide-contact-tsx": () => import("./../src/pages/styleguide/contact.tsx" /* webpackChunkName: "component---src-pages-styleguide-contact-tsx" */),
  "component---src-pages-styleguide-index-tsx": () => import("./../src/pages/styleguide/index.tsx" /* webpackChunkName: "component---src-pages-styleguide-index-tsx" */),
  "component---src-pages-styleguide-typography-tsx": () => import("./../src/pages/styleguide/typography.tsx" /* webpackChunkName: "component---src-pages-styleguide-typography-tsx" */)
}

